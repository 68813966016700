import React from 'react';
import { Button } from 'antd';
import {jsmind, jsMind, jm} from 'jsmind';
import './App.css';
import { Layout, Menu, Breadcrumb } from 'antd';
let host = "";
//let host = "http://localhost:9000";
const { Header, Content, Footer } = Layout;


// from https://github.com/sxwxs/list2table/blob/master/src/list2table.js
function formatTime(number,format) {

    var formateArr  = ['Y','M','D','h','m','s'];
    var returnArr   = [];

    var date = new Date(number * 1000);
    returnArr.push(date.getFullYear());
    returnArr.push(formatNumber(date.getMonth() + 1));
    returnArr.push(formatNumber(date.getDate()));

    returnArr.push(formatNumber(date.getHours()));
    returnArr.push(formatNumber(date.getMinutes()));
    returnArr.push(formatNumber(date.getSeconds()));

    for (var i in returnArr)
    {
    format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
}

function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiList: {
          getFileList: host + "/api/v1/get-doc-list/",
          userInfo: host + "/api/v1/user/",
          scanFile: "/api1/?key=Mc4a_R8OI8fSs&s3=",
          selectedFileList: "/api1/?key=Mc4a_R8OI8fSs&s3=get_selected",
          bigFileList: "/api1/?key=Mc4a_R8OI8fSs&s3=get_big_files_and_dirs",
          searchFileList: "/api3/?key=Mc4a_R8OI8fSs&s1="
      },
      user: {
      },
      view: "list",
      jsmind: {},
      fileList: {children: []}
    }
    this.getUserInfo();
    this.getFileList();
  }
  
  getFileList() {
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let fileList = JSON.parse(xhr.responseText);
          if (!fileList['children']) {
              fileList['children'] = [];
          }
          this.state.fileList = fileList;
          
          this.setState(this.state);
        }
      }
    }
    xhr.open('GET', this.state.apiList.getFileList);
    xhr.send();
  }

  getUserInfo() {
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          console.log(xhr.responseText);
          let userInfo = JSON.parse(xhr.responseText);
          this.state.userInfo = userInfo;
          
          this.setState(this.state);
        }
      }
    }
    xhr.open('GET', this.state.apiList.userInfo);
    xhr.send();
  }

  renderAccount() {
    if (this.state.userInfo) {
      return <div>
        {this.state.userInfo.email}
        <Button href="/edit.html">New Mind Map</Button>
        <div>
          {
            this.state.fileList.children.map((data, index) => {
              return <p>
                <a href={'/edit.html?id='+data.id}>{data.name}</a>  
                <div>{formatTime(data.create_time, "Y/D/M h:m")}</div>
                <div>{formatTime(data.update_time, "Y/D/M h:m")}</div>
              </p>
            })
          }
        </div>
        </div>
    }
    else {
      return <div>
        <Button type="primary" href="https://account.es2q.com/login/oauth/authorize?client_id=350117dbd4f62af3f44e&response_type=code&redirect_uri=https://doc.es2q.com/api/v1/login-call-back/&scope=read&state=casdoor">Login</Button>
        <Button href="https://account.es2q.com/signup/application_webmind">Register</Button>
      </div>

    }
  }

  getMainView() {
    if (this.state.view == "list") {
      return <div>list view</div>
    }
    else {
      let options = {
          container:'jsmind_container',
          theme:'orange',
          editable:true
      };
      console.log(this.state)
    }
  }

  newMind() {
      let options = {
          container:'jsmind_container',
          theme:'orange',
          editable:true
      };
      //this.state.jsmind = jsMind.show(options);
      //console.log(this.state)
      console.log(jm)
      console.log(jsMind)
      console.log(jsmind)

  }

  render() {
    return <div className="App">
      {this.renderAccount()}
    </div>
  }
}

export default App;
